<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4><i class="ti-hummer"> </i> 功能室 | 使用情况</h4>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-md-12">
            <el-button type="primary" @click="rowEdit()">新增记录</el-button>
            <button type="submit" class="el-button el-button--success" @click="exportExcel">
              <span>导出Excel</span>
            </button>
            <!-- </form> -->
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="w-block" style="padding: 2px;">
              <el-select v-model="grid.sea.Func_Room_Id" @change="filter" clearable placeholder="功能室"
                style="margin-left: 5px">
                <el-option v-for="item in func" :key="item.ID" :label="item.Func_Room_Name" :value="item.ID">
                </el-option>
              </el-select>
              <el-select v-model="grid.sea.Activity_Type" @change="filter" clearable placeholder="类型"
                style="margin-left: 5px">
                <el-option v-for="item in activityTypes" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
              <el-date-picker v-model="date.range" @change="filter" type="daterange" value-format="yyyy-MM-dd"
                range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" style="margin-left: 5px" />
              <el-select v-model="grid.sea.Man_User_Id" @change="filter" clearable placeholder="负责人"
                style="margin-left: 5px">
                <el-option v-for="item in tea.ls" :key="item.ID" :label="item.NAME" :value="item.ID">
                </el-option>
              </el-select>
              <!--              <el-input v-model="grid.sea.KEY" placeholder="请输入关键字" style="width: 300px;margin-left: 5px">-->
              <!--                <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>-->
              <!--              </el-input>-->
            </div>
            <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX + 1"
              :page-size="grid.sea.PAGE_SIZE" @page-changed="loadPage">

              <el-table-column type="index" label="序号" width="50" align="center" />
              <el-table-column prop="CREATED_DT" label="日期" width="120" align="center">
                <template slot-scope="{row}">
                  {{ row.CREATED_DT | datetime('YYYY-MM-DD') }}
                </template>
              </el-table-column>
              <el-table-column prop="Func_Room_Name" label="功能室" width="80" align="center" />

              <el-table-column prop="Activity_Type" label="类型" width="150" />
              <el-table-column prop="Activity_Content" label="活动内容" width="150" />
              <el-table-column label="照片" width="80" align="center">
                <template align="center" slot-scope="scope">
                  <el-image v-if="scope.row.LST_Activity_Pics_URL.length > 0"
                    style="width: 50px;height: 50px; margin-top: 8px" fit="cover"
                    :src="scope.row.LST_Activity_Pics_URL[0].Value"
                    :preview-src-list="scope.row.LST_Activity_Pics_URL.map(item => item.Value)" />
                </template>
              </el-table-column>
              <el-table-column label="整理情况">
                <el-table-column prop="Environment_State" label="环境" />
                <el-table-column prop="Facility_State" label="设施" />
                <el-table-column prop="Tool_State" label="教玩具" />
              </el-table-column>
              <el-table-column prop="CreateUserName" label="活动教师" width="100" />
              <el-table-column label="整理照片" width="100" align="center">
                <template align="center" slot-scope="scope">
                  <el-image v-if="scope.row.LST_Organize_Pics_URL.length > 0"
                    style="width: 50px;height: 50px; margin-top: 8px" fit="cover"
                    :src="scope.row.LST_Organize_Pics_URL[0].Value"
                    :preview-src-list="scope.row.LST_Organize_Pics_URL.map(item => item.Value)" />
                </template>
              </el-table-column>
              <el-table-column prop="Man_User_Names" label="负责人" width="200" align="center" />
              <el-table-column prop="Remark" label="备注" width="200" />

              <el-table-column label="操作" width="130">
                <template slot-scope="scope">
                  <el-button type="text" icon="el-icon-edit" @click="rowEdit(scope.row)">编辑</el-button>
                  <el-button type="text" icon="el-icon-delete" @click="rowDel(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </wgrid>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Edit ref="use_edit" :func="func" @on-saved="filter" />
    </div>
  </div>
</template>

<script>
import Edit from "./edit";
import Wgrid from "@/components/wgrid";
import jsFileDownload from 'js-file-download'

export default {
  name: "index",
  components: { Wgrid, Edit, },
  data() {
    return {
      grid: {
        sea: {
          PAGE_SIZE: 12,
          PAGE_INDEX: 0,
          createUserId: this.whale.user.get().ID,
        },
        ls: [],
        total: 0,
        loading: false
      },
      date: { range: [] },
      func: [],
      activityTypes: ['会议', '活动', '上课', '其它'],
      tea: {
        ls: [],
        val: [],
      },
    }
  },
  created() {
    this.getFunc();
    this.getTea();
    this.getList();
  },
  methods: {
    filter() {
      console.log(this.date.range)
      let n = this.date.range.length
      if (n > 0) {
        this.grid.sea.DTS = this.date.range[0]
        this.grid.sea.DTE = this.date.range[1]
      }

      this.grid.sea.PAGE_INDEX = 0;
      this.grid.ls = [];
      this.getList();
    },
    loadPage(pi) {
      this.grid.sea.PAGE_INDEX = pi - 1;
      this.getList();
    },
    getList() {
      console.log('sea', this.grid.sea)
      let self = this;
      this.grid.loading = true;
      this.whale.remote.getCollection({
        url: "/api/School/FUNC/FuncRoomUse/GetPageList",
        data: this.grid.sea,
        completed: function (its, n) {
          self.grid.ls = its;
          self.grid.total = n;
          self.grid.loading = false;
        }
      })
    },
    getFunc() {
      let self = this;
      this.whale.remote.getCollection({
        url: "/api/School/FUNC/FuncRoom/GetPageList",
        completed: function (its) {
          self.func = its
        }
      })
    },
    getTea() {
      let self = this
      this.whale.remote.getCollection({
        url: "/api/School/ORG/TeacherApi/GetList",
        completed(its) {
          self.tea.ls = its;
        }
      })
    },
    rowEdit(r) {
      this.$refs.use_edit.init(r);
    },
    rowDel(r) {
      this.whale.delConfirm(() => {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/School/FUNC/FuncRoomUse/Delete?id=" + r.ID,
          completed: function () {
            //self.filter();
            self.grid.ls = self.grid.ls.filter(o => o.ID != r.ID)
          }
        })
      });
    },
    // exportExcel() {
    //   // let self = this;
    //   this.grid.loading = true;
    //   const formData = new FormData()
    //   formData.append('Func_Room_Id', this.grid.sea.Func_Room_Id || '')
    //   formData.append('Activity_Type', this.grid.sea.Activity_Type || '')
    //   formData.append('DtS', this.grid.sea.DTS || '')
    //   formData.append('DtE', this.grid.sea.DTE || '')
    //   formData.append('Man_User_Id', this.grid.sea.Man_User_Id || '')
    //   formData.append('CreateUserId', this.grid.sea.createUserId || '')
    //   console.log('formData',formData)
    //   this.whale.remote.getExport({
    //     url: "/api/School/FUNC/FuncRoomUse/Export",
    //     data: formData,
    //     completed: function (its) {
    //       jsFileDownload(its, '功能室使用情况' + new Date().getTime() + '.xls')
    //       self.grid.loading = false;
    //     }
    //   })
    // }
    exportExcel() {
      let self = this
      this.grid.loading = true;
      let model = {
        Func_Room_Id: this.grid.sea.Func_Room_Id || 0,
        Activity_Type: '',
        DtS: this.grid.sea.Activity_Type || '',
        DtE: this.grid.sea.DTS || '',
        Man_User_Id: this.grid.sea.DTE || 0,
        CreateUserId: 0,
      }
      this.whale.remote.getExportResult({
        url: "/api/School/FUNC/FuncRoomUse/Export",
        data: model,
        completed: function (its) {
          jsFileDownload(its, '功能室使用情况' + new Date().getTime() + '.xls')
          self.grid.loading = false;
        }
      })
    }
  }
}
</script>
<style>
/* /deep/ .el-table thead.is-group th.el-table__cell {
  border-bottom: 1px solid red !important;
} */
</style>
