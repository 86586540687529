<template>
  <el-dialog :visible.sync="sta.show" width="750px">
    <span slot="title">增加/修改 功能室</span>
    <div class="row">
      <div class="col-md-12">
        <el-row>
          <el-col :span="12">
            <div class="form-group">
              <label class="label1"><b>功能室名称：</b></label>
              <el-select v-model="info" value-key="ID" placeholder="请选择" style="width: 60%" @change="roomChange">
                <el-option
                    v-for="item in func"
                    :key="item.ID"
                    :label="item.Func_Room_Name"
                    :value="item">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="form-group">
              <label class="label1"><b>设施：</b></label>
              <el-input type="text" v-model="info.Facillities" style="width: 60%" readonly/>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="form-group">
              <label class="label1"><b>位置：</b></label>
              <el-input type="text" v-model="info.Location" style="width: 60%" readonly/>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="form-group">
              <label class="label1"><b>教玩具：</b></label>
              <el-input type="text" v-model="info.Room_Tools" style="width: 60%" readonly/>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="form-group">
              <label class="label1"><b>活动类型：</b></label>
              <el-select v-model="activityType" placeholder="请选择" style="width: 60%">
                <el-option
                    v-for="item in activityTypes"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="12">
            <div v-if="activityType=='其它'" class="form-group">
              <label class="label1"><b>其他类型：</b></label>
              <el-input type="text" v-model="model.Activity_Type" style="width: 60%"/>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="form-group">
              <label class="label1"><b>活动内容：</b></label>
              <el-input type="text" v-model="model.Activity_Content" style="width: 60%"/>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="form-group" style="display: flex">
              <label class="label1"><b>活动照片：</b></label>
              <uploadImg @getImgUrl="getImgUrl" :limit="6"></uploadImg>
<!--              <el-upload accept="image/*" action="" :limit="6" list-type="picture-card" :auto-upload="false" multiple-->
<!--                         :on-change="onChange" :on-remove="() => { model.PIC_B64 = '' }">-->
<!--                <i class="el-icon-plus"></i>-->
<!--              </el-upload>-->
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <div class="form-group">
              <label class="label1"><b>环境：</b></label>
              <el-select v-model="model.Environment_State" placeholder="请选择" style="width: 60%">
                <el-option
                    v-for="item in environmentStates"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="form-group">
              <label class="label1"><b>设施：</b></label>
              <el-select v-model="model.Facility_State" placeholder="请选择" style="width: 60%">
                <el-option
                    v-for="item in facilityStates"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="form-group">
              <label class="label1"><b>教玩具：</b></label>
              <el-select v-model="model.Tool_State" placeholder="请选择" style="width: 60%">
                <el-option
                    v-for="item in toolStates"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="form-group">
              <label class="label1"><b>备注：</b></label>
              <el-input type="text" v-model="model.Remark" style="width: 60%"/>
            </div>
          </el-col>
        </el-row>
<!--        <el-row v-if="info.Man_User_IdList&&info.Man_User_IdList.find(item=>item==user.ID)">-->
<!--          <el-col :span="24">-->
<!--            <div class="form-group" style="display: flex">-->
<!--              <label class="label1"><b>整理照片：</b></label>-->
<!--              <el-upload accept="image/*" action="" :limit="6" list-type="picture-card" :auto-upload="false" multiple-->
<!--                         :on-change="onChange" :before-remove="onRemove" :show-file-list="false"	:file-list="fileList" >-->
<!--                <i class="el-icon-plus"></i>-->
<!--              </el-upload>-->
<!--            </div>-->
<!--          </el-col>-->
<!--        </el-row>-->
      </div>
    </div>
    <div slot="footer" v-loading="sta.loading">
      <el-button icon="el-icon-close" @click="sta.show=false">取消</el-button>
      <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import uploadImg from "@/components/uploadImg";
export default {
  name: "edit",
  props:['func'],
  components:{uploadImg},
  data() {
    return {
      user:this.whale.user.get(),
      fileList:[],
      sta: {
        show: false,
        loading: false
      },
      info:{},
      // func: [],
      model: {},
      activityType:'',
      activityTypes:['会议','活动','上课','其它'],
      environmentStates:['好','差'],
      facilityStates:['完好','破损'],
      toolStates:['完整','损耗'],
      isCreate:false,
    }
  },
  methods: {
    init(model) {
      this.sta = {show: true, loading: false}
      if (model==undefined || model==null) {
        this.info = {}
        this.model = {}
        this.activityType = ''
        this.isCreate = true
      } else {
        this.isCreate = false
        this.model = model
        this.info = this.func.find(item=>item.ID == model.Func_Room_Id)
        let type = this.activityTypes.find(item=>item==model.Activity_Type)
        if(type){
          this.activityType = model.Activity_Type
        }else{
          this.activityType = '其它'
        }
      }
    },
    getImgUrl(val){
      this.model.Activity_Pics = val.join(',')
    },
    onRemove(file){
      console.log('onRemove',file)
    },
    onChange(file,fileList) {
      console.log('fileList',fileList)
      console.log('this.fileList',this.fileList)
      let self = this;
      this.whale.image.compress({
        file: file.raw,
        callback(cf) {
          self.whale.image.toB64({
            file: cf,
            callback(b64) {
              self.model.PIC_B64 = b64;
              // self.whale.remote.getResult({
              //   url: "/api/School/FUNC/FuncRoomUse/UpUseOrManPicture",
              //   data:{
              //     b64,
              //     Id:self.model.ID,
              //     IsOrganize:0,
              //   },
              //   completed: function () {}
              // })
            }
          })
        }
      })
    },
    roomChange(val) {
      this.info = val
    },
    submit() {
      let self = this
      if(self.activityType !== '其它'){
        self.model.Activity_Type = self.activityType
      }
      self.model.Func_Room_Id = self.info.ID
      if(self.isCreate){
        this.whale.remote.getResult({
          url:"/api/School/FUNC/FuncRoomUse/CreateNew",
          data:self.model,
          finally(){self.sta.loading=false;},
          completed:function (){
            self.sta.show=false;
            self.$emit("on-saved");
          }
        })
      }else{
        this.whale.remote.getResult({
          url:"/api/School/FUNC/FuncRoomUse/Update",
          data:self.model,
          finally(){self.sta.loading=false;},
          completed:function (){
            self.sta.show=false;
            self.$emit("on-saved");
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.label1{
  width: 6em;
  text-align: right;
  margin-right: 5px;
}
</style>